define('m01new/controllers/downloadcenter', ['exports', 'ember', 'ember-i18n'], function (exports, Ember, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    savingInProgress: false,
    saved: false,
    saveFile: "",
    pdfErstellt: false,
    dlPaketFilename: "testpaket2016",
    dlPaketLink: "http://www.google.de",
    packageIsBeingCreated: false,
    packageCreated: false,
    inputCheckbox: false,
    pdfCheckbox: false,
    pinfoCheckbox: false,
    pkatalogCheckbox: false,
    pcadCheckbox: false,
    etaCheckbox: false,
    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "http://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/Catalog.pdf",
    eta: "http://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/ETA-110190.pdf",
    validScrewSelected: false,
    cadForValidSrew: false,
    cid: "",

    etaNummer: "12/0373",
    etaFehlt: true,
    katalogFehlt: true,
    berechnungDisabled: true,
    dxfDisabled: true,

    pdfFile: "",
    i18n: Ember['default'].inject.service(),
    ttInNichtGespeichert: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('eingabedatennichtgespeichert');
    }),
    ttPdfNochNichtErstellt: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('pdfnochnichterstellt');
    }),
    ttProduktkatalogFehlt: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('produktkatalogFehlt');
    }),
    ttKeineSchraubeGewaehlt: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('nochkeineschraubegewaehlt');
    }),
    ttKeinDLErzeugt: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('nochkeindlerzeugt');
    }),

    actions: {

      createDownloadPackage: function createDownloadPackage() {

        var self = this;

        var application = self.controllerFor('application');

        var applicationdata = application.get('model').application.objectAt(0);

        var cid = applicationdata.get('Calculation_ID');

        if (self.get('dlfilename') === "" || self.get('dlfilename') === undefined) {
          var filename = cid;
        } else {
          var filename = self.get('dlfilename');
        }

        var inputCBvalue = self.get('inputCheckbox');
        var pdfCBvalue = self.get('pdfCheckbox');
        var pinfoCBvalue = self.get('pinfoCheckbox');
        var pkatalogCBvalue = self.get('pkatalogCheckbox');
        var pcadCBvalue = self.get('pcadCheckbox');
        var etaCBvalue = self.get('etaCheckbox');

        self.set('packageIsBeingCreated', true);

        $.ajax({
          type: "POST",
          url: "http://136.243.4.143/berechnungsservice/dlPaketErstellen/",
          data: {
            cid: cid,
            filename: filename,
            input: inputCBvalue,
            pdf: pdfCBvalue,
            pinfo: pinfoCBvalue,
            pkatalog: pkatalogCBvalue,
            pcad: pcadCBvalue,
            eta: etaCBvalue
          }
        }).done(function (data) {
          self.set('dlPaketFilename', filename);
          self.set('dlPaketLink', data);
          self.set('packageIsBeingCreated', false);
          self.set('packageCreated', true);
        });
      }

      //   saveAction: function() {
      //
      //     console.log('speichern()');
      //
      //     var self = this;
      //
      //     self.set('savingInProgress', true);
      //
      //     var application = self.controllerFor('application');
      //
      //     var applicationdata = application.get('model').application.objectAt(0);
      //     var applicationdataJSON = JSON.stringify(applicationdata);
      //
      //     console.log(self.controllerFor('projektdaten').model);
      //
      //     var projektdatendata = this.controllerFor('application').get('model').projektdaten.objectAt(0);
      //     var projektdatendataJSON = JSON.stringify(projektdatendata);
      //
      //     var bauteiledata = this.controllerFor('application').get('model').bauteile.objectAt(0);
      //     var bauteiledataJSON = JSON.stringify(bauteiledata);
      //
      //     var anschlussgeometriedata = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);
      //     var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);
      //
      //     var lasteinwirkungdata = this.controllerFor('application').get('model').lasteinwirkung.objectAt(0);
      //     var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
      //
      //     if (self.debug) {
      //       console.log("projektdatendata: " + projektdatendataJSON);
      //       console.log("applicationdataJSON: " + applicationdataJSON);
      //       console.log("bauteiledataJSON: " + bauteiledataJSON);
      //       console.log("anschlussgeometriedataJSON: " + anschlussgeometriedataJSON);
      //       console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
      //     }
      //
      //     var projektdatendataJSONcut = projektdatendataJSON.substring(1, projektdatendataJSON.length - 1);
      //     var bauteiledataJSONcut = bauteiledataJSON.substring(1, bauteiledataJSON.length - 1);
      //     var anschlussgeometriedataJSONcut = anschlussgeometriedataJSON.substring(1, anschlussgeometriedataJSON.length - 1);
      //     var lasteinwirkungdataJSONcut = lasteinwirkungdataJSON.substring(1, lasteinwirkungdataJSON.length - 1);
      //
      //     var separators = [':', ','];
      //     if (self.debug) {
      //       //console.log(separators.join('|'));
      //     }
      //     var bauteildataarray = bauteiledataJSONcut.split(new RegExp(separators.join('|'), 'g'));
      //     var timberelementstringht = "\"TimberElementHT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[5] + ",  \"h\": " + bauteildataarray[7] + " } },";
      //     var timberelementstringnt = "\"TimberElementNT\" : { \"Querschnittswerte\" : { \"b\" : " + bauteildataarray[17] + ",  \"h\": " + bauteildataarray[19] + " } }";
      //
      //
      //     var JSONdata = "{" + projektdatendataJSONcut + "," + bauteiledataJSONcut + "," + timberelementstringht + timberelementstringnt + "," + anschlussgeometriedataJSONcut + "," + lasteinwirkungdataJSONcut + "}";
      //     if (self.debug) {
      //       console.log("complete: " + JSONdata);
      //     }
      //
      //     var initialized = applicationdata.get('initialized');
      //
      //     if (!initialized) {
      //
      //       $.ajax({
      //         type: "POST",
      //         url: "http://136.243.4.143/berechnungsservice/m06/DE/initialisiereBerechnung/",
      //         //url: "http://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
      //         data: {
      //           kennung: applicationdataJSON,
      //           projektdaten: projektdatendataJSON,
      //           paras: JSONdata,
      //           modul: "m06",
      //           pfad: "D:/swgengineering/bin/"
      //         }
      //       }).done(function(data) {
      //
      //         var cid = data;
      //         applicationdata.set('Calculation_ID', cid);
      //         applicationdata.set('initialized', true);
      //
      //         $.ajax({
      //           type: "POST",
      //           url: "http://136.243.4.143/berechnungsservice/speichereBerechnung/" + cid + "/",
      //           data: {
      //             kennung: applicationdataJSON,
      //             paras: JSONdata
      //           }
      //         }).done(function(data) {
      //           //console.log(data);
      //           //window.open(data);
      //           self.set('saveFile', data);
      //           self.set('savingInProgress', false);
      //           self.set('saved', true);
      //           // datei öffnen, bzw. link anzeigen
      //         });
      //       });
      //
      //     } else {
      //
      //       var cid = applicationdata.get('Calculation_ID');
      //
      //       $.ajax({
      //         type: "POST",
      //         url: "http://136.243.4.143/berechnungsservice/speichereBerechnung/" + cid + "/",
      //         data: {
      //           kennung: applicationdataJSON,
      //           paras: JSONdata
      //         }
      //       }).done(function(data) {
      //         self.set('savingInProgress', false);
      //         self.set('saved', true);
      //         self.set('saveFile', data);
      //         //console.log(data);
      //         //window.open(data);
      //         // datei öffnen, bzw. link anzeigen
      //       });
      //
      //
      //     }
      //
      //     self.set('unsaved', false);
      //
      //   }
      //
    }

  });

});