define('m01new/controllers/dachgeometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    validations: {
      fhoehe: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 25
        }
      },
      dneigung: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 75
        }
      },
      dneigung2: {
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 75
        }
      },
      gbreite: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.0
        }
      },
      glaenge: {
        numericality: {
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 999.9
        }
      },
      uegiebel: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      },
      uetraufe: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      },
      uefirst: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 5
        }
      }
    },

    eingabenForLastannahmen: true,

    ueberdaemmteflaecheAktiv: false,

    dgeometrie: [],
    dachgeometrieTranslationIDs: [{
      name: "satteldach",
      id: 0
    }, {
      name: "pultdach",
      id: 1
    }, {
      name: "walmdach",
      id: 2
    }],

    ttHoeheFirst: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0.1 - 25.0 [m]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttDachneigung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 5 - 75 [°]";
    }),
    ttDachneigungWalm: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 75 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttGebaeudebreite: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999.9 [m]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttGebaeudelaenge: Ember['default'].computed('i18n.locale', {
      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 1 - 999.9 [m]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttUeGiebel: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5.0 [m]";
    }),
    ttUeFirst: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5.0 [m]";
    }),
    ttUeTraufe: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0 - 5.0 [m]";
    }),
    ttUeberdaemmteflaeche: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " > Dachfläche [m²]";
    }),
    i18n: Ember['default'].inject.service(),

    letzterLasteinwirkungsReiter: 'lasteinwirkung',

    // Default Werte fuer Comboboxes
    konstruktion: 0,
    dachgeometrie: 0,

    fhoehe: "",
    dneigung: "",
    dneigung2: "40.0",
    gbreite: "",
    glaenge: "",
    uegiebel: "0.0",
    uetraufe: "0.0",
    uefirst: "0.0",
    reduktionsmass: "",
    dachflaeche: "",
    ueberdaemmteflaeche: "",
    berechneteueberdaemmteflaeche: "",
    l_Sparren: "",

    dachneigungWalmGleichDachneigung1: true,
    ueberdaemmteFlaecheGesperrt: false,
    walmdach: false,
    pultdach: false,
    openFromFile: false,
    displayErrors: true,

    wuerth: true,
    bauder: false,

    debug: true,

    init: function init() {

      this._super();

      var self = this;
      var application = this.controllerFor('application');
      self.set('bauder', application.get('bauder'));

      if (application.get('bauder') === true || application.get('hilti') === true) {
        self.set('wuerth', false);
        self.set('ueberdaemmteFlaecheGesperrt', true);
      }

      self.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in dachgeometrie: ");
        console.log(values);
      }

      self.set('openFromFile', true);

      self.set('dachgeometrie', parseInt(values.DachFormID));
      self.set('openFromFile', false);
      self.watchDachgeometrie();
      self.set('openFromFile', true);

      self.set('uegiebel', '');
      self.set('uefirst', '');
      self.set('uetraufe', '');

      this.send('validation');

      self.set('uegiebel', String((parseFloat(values.Ue_Giebel) / 100).toFixed(1)));
      this.setX3D(parseFloat(values.Ue_Giebel) / 100, { target: { name: "uegiebel" } });

      self.set('uefirst', (parseFloat(values.Ue_First) / 100).toFixed(1));
      this.setX3D(parseFloat(values.Ue_First) / 100, { target: { name: "uefirst" } });

      self.set('uetraufe', (parseFloat(values.Ue_Traufe) / 100).toFixed(1));
      this.setX3D(parseFloat(values.Ue_Traufe) / 100, { target: { name: "uetraufe" } });

      self.set('dachgeometrie', parseInt(values.DachFormID));
      self.set('openFromFile', false);
      self.watchDachgeometrie();
      self.set('openFromFile', true);

      self.set('konstruktion', parseInt(values.konstruktionID));

      self.set('fhoehe', (parseFloat(values.hFirst) / 100).toFixed(1));
      this.setX3D(parseFloat(values.hFirst) / 100, { target: { name: "fhoehe" } });

      self.set('dneigung', parseFloat(values.ALFA_Grad).toFixed(1));
      self.set('dneigung', parseFloat(values.DN).toFixed(1));
      this.setX3D(parseFloat(values.ALFA_Grad), { target: { name: "dneigung" } });

      self.set('dneigung2', parseFloat(values.DN_Walm).toFixed(1));

      self.set('gbreite', (parseFloat(values.gebaeudeBreite) / 100).toFixed(1));
      this.setX3D(parseFloat(values.gebaeudeBreite) / 100, { target: { name: "gbreite" } });

      self.set('glaenge', (parseFloat(values.gebaeudeLaenge) / 100).toFixed(1));
      this.setX3D(parseFloat(values.gebaeudeLaenge) / 100, { target: { name: "glaenge" } });

      self.set('dachflaeche', (parseFloat(values.A_Dach) / 10000).toFixed(2));

      self.set('ueberdaemmteflaeche', (parseFloat(values.ueberdaemmteFlaeche) / 10000).toFixed(2));

      self.send('validation', -1, { target: { name: "foo" } });

      self.set('openFromFile', false);

      self.setKonstruktion();

      // console.log('&&&&& errors Dachgeometrie');
      // console.log(this);
      // console.log(this.errors);
      // console.log(this.errors.get('rwinkelv'));
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      if (self.get('wuerth') === true) {
        self.setKonstruktionen();
      }
      self.setDachgeometrien();
    },

    setKonstruktionen: function setKonstruktionen() {
      var self = this;
      var indices = dachgeometrieDB.konstruktion.findBy('id', 0).indices;

      var application = self.controllerFor('application');

      // if(application.get('bemessungsvorschrift') !== "EC1/EC5 (DE)"){
      //   indices = [0];
      // }

      self.set('konstruktionen', self.getSelectFieldContent('konstruktionen', indices));
    },

    setDachgeometrien: function setDachgeometrien() {
      var self = this;
      var indices = dachgeometrieDB.dachgeometrieTranslationIDs.findBy('id', 0).indices;
      self.set('dgeometrie', self.getSelectFieldContent('dachgeometrie', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      var self = this;
      switch (db) {
        case 'konstruktionen':
          dbContent = dachgeometrieDB.konstruktion.findBy('id', 0).content;
          break;
        case 'dachgeometrie':
          dbContent = dachgeometrieDB.dachgeometrieTranslationIDs.findBy('id', 0).content;
          break;
      }

      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (String(this.get('fhoehe')).indexOf(",") !== -1) {
          this.set('fhoehe', this.get('fhoehe').replace(",", "."));
        }

        if (String(this.get('dneigung')).indexOf(",") !== -1) {
          this.set('dneigung', this.get('dneigung').replace(",", "."));
        }

        if (String(this.get('dneigung2')).indexOf(",") !== -1) {
          this.set('dneigung2', this.get('dneigung2').replace(",", "."));
        }

        if (String(this.get('gbreite')).indexOf(",") !== -1) {
          this.set('gbreite', this.get('gbreite').replace(",", "."));
        }

        if (String(this.get('glaenge')).indexOf(",") !== -1) {
          this.set('glaenge', this.get('glaenge').replace(",", "."));
        }

        if (String(this.get('uegiebel')).indexOf(",") !== -1) {
          this.set('uegiebel', this.get('uegiebel').replace(",", "."));
          this.setfuehrendeNull('uegiebel');
        }

        if (String(this.get('uetraufe')).indexOf(",") !== -1) {
          this.set('uetraufe', this.get('uetraufe').replace(",", "."));
          this.setfuehrendeNull('uetraufe');
        }

        if (String(this.get('uefirst')).indexOf(",") !== -1) {
          this.set('uefirst', this.get('uefirst').replace(",", "."));
          this.setfuehrendeNull('uefirst');
        }

        if (String(this.get('reduktionsmass')).indexOf(",") !== -1) {
          this.set('reduktionsmass', this.get('reduktionsmass').replace(",", "."));
        }

        var gbreite = this.get('gbreite');
        var glaenge = this.get('glaenge');
        var uegiebel = this.get('uegiebel');
        var uetraufe = this.get('uetraufe');
        var dneigung = this.get('dneigung');
        var dneigung2 = this.get('dneigung2');
        var uefirst = this.get('uefirst');

        if (uegiebel === '') {
          uegiebel = 0;
        }

        if (uetraufe === '') {
          uetraufe = 0;
        }

        if (uefirst === '') {
          uefirst = 0;
        }

        if (this.get('dachgeometrie') === 0) {
          this.set('dachflaeche', rooftools.satteldachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), parseFloat(uegiebel), parseFloat(uetraufe), parseFloat(dneigung)));
        } else if (this.get('dachgeometrie') === 1) {
          this.set('dachflaeche', rooftools.pultdachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), parseFloat(uegiebel), parseFloat(uetraufe), parseFloat(dneigung), parseFloat(uefirst)));
        } else if (this.get('dachgeometrie') === 2) {
          this.set('dachflaeche', rooftools.walmdachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), parseFloat(uegiebel), parseFloat(uetraufe), parseFloat(dneigung), parseFloat(uefirst)));
        }
      }
    }).observes('fhoehe', 'dneigung', 'dneigung2', 'gbreite', 'glaenge', 'uegiebel', 'uetraufe', 'uefirst', 'reduktionsmass'),

    uedaemmflaeche: (function () {
      var gbreite = Number(this.get('gbreite'));
      var glaenge = Number(this.get('glaenge'));
      var fhoehe = Number(this.get('fhoehe'));
      var dneigung = Number(this.get('dneigung'));
      var dneigung2 = Number(this.get('dneigung2'));

      var dh = 0,
          A_Fassade = 0;

      if (this.get('konstruktion') === 0) {
        if (this.get('dachgeometrie') === 0) {
          this.set('ueberdaemmteflaeche', rooftools.satteldachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), 0, 0, parseFloat(dneigung)));
        } else if (this.get('dachgeometrie') === 1) {
          this.set('ueberdaemmteflaeche', rooftools.pultdachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), 0, 0, parseFloat(dneigung), 0));
        } else if (this.get('dachgeometrie') === 2) {
          this.set('ueberdaemmteflaeche', rooftools.walmdachflaecheBerechnen(parseFloat(gbreite), parseFloat(glaenge), 0, 0, parseFloat(dneigung), 0));
        }
      } else if (this.get('konstruktion') === 1) {
        if (this.get('dachgeometrie') === 0) {
          dh = gbreite / 2 * Math.tan(dneigung / 180 * Math.PI);
          A_Fassade = 2 * ((fhoehe - dh) * (gbreite + glaenge) + dh / 2 * gbreite);
        } else if (this.get('dachgeometrie') === 1) {
          dh = gbreite * Math.tan(dneigung / 180 * Math.PI);
          A_Fassade = 2 * ((fhoehe - dh) * (gbreite + glaenge) + dh / 2 * gbreite);
        } else if (this.get('dachgeometrie') === 2) {
          A_Fassade = 2 * (fhoehe * (gbreite + glaenge));
        }
        this.set('ueberdaemmteflaeche', A_Fassade.toFixed(1));
      }

      this.set('berechneteueberdaemmteflaeche', this.get('ueberdaemmteflaeche'));
      var dachgeometriedata = this.controllerFor('application').get('model').dachgeometrie.objectAt(0);
      dachgeometriedata.set('berechneteUeberdaemmteFlaeche', (parseFloat(this.get('berechneteueberdaemmteflaeche')) * 10000).toFixed(1));
    }).observes('dneigung', 'dneigung2', 'gbreite', 'glaenge', 'konstruktion'),

    setfuehrendeNull: function setfuehrendeNull(ind) {
      if (this.get(ind).charAt(0) === '.') {
        this.set(ind, '0.');
      }
    },

    watchDachgeometrie: (function () {

      // console.log('&&&&&');
      // console.log('watchDachgeometrie');
      //
      // console.log('open from File: '+this.openFromFile);
      if (!this.openFromFile) {

        // console.log('setup Dachgeomatrie');

        var gbreite = this.get('gbreite');
        var glaenge = this.get('glaenge');
        var uegiebel = this.get('uegiebel');
        var uetraufe = this.get('uetraufe');
        var dneigung = this.get('dneigung');
        var dneigung2 = this.get('dneigung2');
        var uefirst = this.get('uefirst');

        var self = this;

        var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        applicationdata.set('treeLoaded', false);
        applicationdata.set('initialized', false);
        applicationdata.set('pdfErstellt', false);

        var application = self.controllerFor('application');
        application.set('dachgeometrieInvalid', true);

        var dachgeometrie = self.controllerFor('application').get('model').dachgeometrie.objectAt(0);
        var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

        if (this.get('dachgeometrie') === 2) {
          console.log('ist walmdach');
          this.set('dachflaeche', rooftools.walmdachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung, uefirst));
          this.set('walmdach', true);
          this.set('pultdach', false);
          x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('ausfuehrung', 'walmdach');
          dachgeometrie.set('DachFormID', this.get('dachgeometrie'));
          x3d.set('dachgeometrieGeandert', !x3d.get('dachgeometrieGeandert'));

          if (self.get('dachneigungWalmGleichDachneigung1') === true) {
            self.set('dneigung2', self.get('dneigung'));
          } else {
            self.set('dneigung2', '');
          }
        } else if (this.get('dachgeometrie') === 1) {
          console.log('ist pultdach');
          this.set('dachflaeche', rooftools.pultdachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung, uefirst));
          this.set('pultdach', true);
          this.set('walmdach', false);
          x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('ausfuehrung', 'pultdach');
          dachgeometrie.set('DachFormID', this.get('dachgeometrie'));
          x3d.set('dachgeometrieGeandert', !x3d.get('dachgeometrieGeandert'));

          self.set('dneigung2', "40.0");
          this.set('dneigung2', "40.0");
        } else {
          console.log('ist satteldach');
          this.set('dachflaeche', rooftools.satteldachflaecheBerechnen(gbreite, glaenge, uegiebel, uetraufe, dneigung));
          this.set('pultdach', false);
          this.set('walmdach', false);
          x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('ausfuehrung', 'satteldach');
          dachgeometrie.set('DachFormID', this.get('dachgeometrie'));
          x3d.set('dachgeometrieGeandert', !x3d.get('dachgeometrieGeandert'));

          self.set('dneigung2', "40.0");
          this.set('dneigung2', "40.0");
        }

        // this.set('ueberdaemmteflaeche', rooftools.dachflaecheReduzieren(this.get('dachflaeche'), this.get('reduktionsmass')));
        this.uedaemmflaeche();
        this.set('openFromFile', true);
        this.send('validation');
        this.set('openFromFile', false);

        self.controllerFor('lasteinwirkungdk').set('formbeiwertTrigger', !self.controllerFor('lasteinwirkungdk').get('formbeiwertTrigger'));
      }

      this.setGrenzwert(this.get('dachgeometrie'));
    }).observes('dachgeometrie'),

    watchEingabenFuerLastannahmen: (function () {

      var self = this;
      self.controllerFor('lasteinwirkungdk').set('formbeiwertTrigger', !self.controllerFor('lasteinwirkungdk').get('formbeiwertTrigger'));
    }).observes('dachgeometrie', 'fhoehe', 'dneigung', 'dneigung2', 'gbreite', 'glaenge', 'uegiebel', 'uetraufe', 'uefirst'),

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {
          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('dachgeometrieInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var dachgeometriedata = self.controllerFor('application').get('model').dachgeometrie.objectAt(0);

            var ltraufeHelper = (parseFloat(self.get('glaenge')) + 2 * parseFloat(self.get('uegiebel'))) * 100;
            dachgeometriedata.set('ALFA_Grad', parseFloat(self.get('dneigung')).toFixed(1));
            dachgeometriedata.set('DN', parseFloat(self.get('dneigung')).toFixed(1));
            dachgeometriedata.set('DN_Grad', parseFloat(self.get('dneigung')).toFixed(1));

            if (self.get('dachneigungWalmGleichDachneigung1')) {
              dachgeometriedata.set('DN_Walm', parseFloat(self.get('dneigung')).toFixed(1));
            } else {
              dachgeometriedata.set('DN_Walm', parseFloat(self.get('dneigung2')).toFixed(1));
            }

            dachgeometriedata.set('hFirst', (parseFloat(self.get('fhoehe')) * 100).toFixed(1));
            dachgeometriedata.set('LTraufe', (parseFloat(self.get('glaenge')) + 2 * parseFloat(self.get('uegiebel'))) * 100);
            dachgeometriedata.set('gebaeudeBreite', (parseFloat(self.get('gbreite')) * 100).toFixed(1));
            dachgeometriedata.set('gebaeudeLaenge', (parseFloat(self.get('glaenge')) * 100).toFixed(1));
            dachgeometriedata.set('Ue_Giebel', (parseFloat(self.get('uegiebel')) * 100).toFixed(1));
            dachgeometriedata.set('Ue_First', (parseFloat(self.get('uefirst')) * 100).toFixed(1));
            dachgeometriedata.set('Ue_Traufe', (parseFloat(self.get('uetraufe')) * 100).toFixed(1));
            dachgeometriedata.set('ueberdaemmteFlaeche', (parseFloat(self.get('ueberdaemmteflaeche')) * 10000).toFixed(1));
            dachgeometriedata.set('A_Dach', (parseFloat(self.get('dachflaeche')) * 10000).toFixed(1));

            var breite = parseFloat(self.get('gbreite')).toFixed(1);
            var neigung = parseFloat(self.get('dneigung')).toFixed(1);
            var neigung2 = parseFloat(self.get('dneigung2')).toFixed(1);
            var ueberstandgiebel = parseFloat(self.get('uegiebel')).toFixed(1);
            var ueberstandtraufe = parseFloat(self.get('uetraufe')).toFixed(1);
            var ueberstandfirst = 0;
            var sparrenlaenge, firstlaenge;

            if (self.get('pultdach')) {
              ueberstandfirst = parseFloat(self.get('uefirst'));
              sparrenlaenge = (Number(breite) + Number(ueberstandtraufe) + Number(ueberstandfirst)) / Math.cos(neigung * Math.PI / 180);
            } else {
              sparrenlaenge = (Number(breite) / 2 + Number(ueberstandtraufe) + ueberstandfirst) / Math.cos(neigung * Math.PI / 180);
            }

            if (self.get('walmdach')) {
              firstlaenge = parseFloat(self.get('glaenge')) - 2 * (Number(breite) / 2 * Math.tan(neigung2 * Math.PI / 180) / Math.tan(neigung * Math.PI / 180));
            } else {
              firstlaenge = parseFloat(self.get('glaenge')) + Number(ueberstandgiebel) * 2;
            }
            // var sparrenlaenge = breite / 2 / Math.cos(neigung * Math.PI / 180);

            self.set('l_Sparren', sparrenlaenge.toFixed(2));

            dachgeometriedata.set('L_Sp', parseFloat(sparrenlaenge * 100).toFixed(1));
            dachgeometriedata.set('LOrtgang', parseFloat(sparrenlaenge * 100).toFixed(1));
            dachgeometriedata.set('LFirst', parseFloat(firstlaenge * 100).toFixed(1));

            // ##################################################################################################
            // hier endet das windlast zeug
          } else {
              self.set('displayErrors', true);
              application.set('dachgeometrieInvalid', true);
            }
        });
      },

      fhoeheIsSelected: function fhoeheIsSelected() {
        var firsthoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('firsthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('firsthoeheAktiv', !firsthoeheAktiv);
        if (this.get('fhoehe') !== "") {
          var fhoehe = parseFloat(this.get('fhoehe').replace(",", "."));
          this.set('fhoehe', fhoehe.toFixed(1));
          this.send('validation', fhoehe.toFixed(1), { target: { name: "fhoehe" } });
        }

        if (firsthoeheAktiv === false) {
          document.getElementsByName('fhoehe')[0].setSelectionRange(0, this.get('fhoehe').length);
        }
      },

      dneigungIsSelected: function dneigungIsSelected() {
        var dneigungAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('dneigungAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('dneigungAktiv', !dneigungAktiv);
        if (this.get('dneigung') !== "") {
          var dneigung = parseFloat(this.get('dneigung').replace(",", "."));
          this.set('dneigung', dneigung.toFixed(1));
          this.send('validation', dneigung.toFixed(1), { target: { name: "dneigung" } });
        }

        if (dneigungAktiv === false) {
          document.getElementsByName('dneigung')[0].setSelectionRange(0, this.get('dneigung').length);
        }
      },

      dneigung2IsSelected: function dneigung2IsSelected() {
        var dneigung2Aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('dneigung2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('dneigung2Aktiv', !dneigung2Aktiv);
        if (this.get('dneigung2') !== "") {
          var dneigung = parseFloat(this.get('dneigung').replace(",", "."));
          this.set('dneigung', dneigung.toFixed(1));
          this.send('validation', dneigung.toFixed(1), { target: { name: "dneigung2" } });
        }

        if (dneigung2Aktiv === false) {
          document.getElementsByName('dneigung2')[0].setSelectionRange(0, this.get('dneigung2').length);
        }
      },

      gbreiteIsSelected: function gbreiteIsSelected() {
        var gbreiteAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('gbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('gbreiteAktiv', !gbreiteAktiv);
        if (!this.get('dachneigungWalmGleichDachneigung1') && this.get('walmdach')) {
          this.setDachneigung2();
        }
        if (this.get('gbreite') !== "") {
          var gbreite = parseFloat(this.get('gbreite').replace(",", "."));
          this.set('gbreite', gbreite.toFixed(1));
          this.send('validation', gbreite.toFixed(1), { target: { name: "gbreite" } });
        }

        if (gbreiteAktiv === false) {
          document.getElementsByName('gbreite')[0].setSelectionRange(0, this.get('gbreite').length);
        }
      },

      glaengeIsSelected: function glaengeIsSelected() {
        var glaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('glaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('glaengeAktiv', !glaengeAktiv);
        if (!this.get('dachneigungWalmGleichDachneigung1') && this.get('walmdach')) {
          this.setDachneigung2();
        }
        if (this.get('glaenge') !== "") {
          var glaenge = parseFloat(this.get('glaenge').replace(",", "."));
          this.set('glaenge', glaenge.toFixed(1));
          this.send('validation', glaenge.toFixed(1), { target: { name: "glaenge" } });
        }

        if (glaengeAktiv === false) {
          document.getElementsByName('glaenge')[0].setSelectionRange(0, this.get('glaenge').length);
        }
      },

      uegiebelIsSelected: function uegiebelIsSelected() {
        var uegiebelAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uegiebelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('uegiebelAktiv', !uegiebelAktiv);
        if (this.get('uegiebel') !== "") {
          var uegiebel = parseFloat(this.get('uegiebel').replace(",", "."));
          this.set('uegiebel', uegiebel.toFixed(1));
          this.send('validation', uegiebel.toFixed(1), { target: { name: "uegiebel" } });
        }

        if (uegiebelAktiv === false) {
          document.getElementsByName('uegiebel')[0].setSelectionRange(0, this.get('uegiebel').length);
        }
      },

      uetraufeIsSelected: function uetraufeIsSelected() {
        var uetraufeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uetraufeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('uetraufeAktiv', !uetraufeAktiv);
        if (this.get('uetraufe') !== "") {
          var uetraufe = parseFloat(this.get('uetraufe').replace(",", "."));
          this.set('uetraufe', uetraufe.toFixed(1));
          this.send('validation', uetraufe.toFixed(1), { target: { name: "uetraufe" } });
        }

        if (uetraufeAktiv === false) {
          document.getElementsByName('uetraufe')[0].setSelectionRange(0, this.get('uetraufe').length);
        }
      },

      uefirstIsSelected: function uefirstIsSelected() {
        var uefirstAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('uefirstAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('uefirstAktiv', !uefirstAktiv);
        if (this.get('uefirst') !== "") {
          var uefirst = parseFloat(this.get('uefirst').replace(",", "."));
          this.set('uefirst', uefirst.toFixed(1));
          this.send('validation', uefirst.toFixed(1), { target: { name: "uefirst" } });
        }

        if (uefirstAktiv === false) {
          document.getElementsByName('uefirst')[0].setSelectionRange(0, this.get('uefirst').length);
        }
      },

      ueberdaemmteflaecheIsSelected: function ueberdaemmteflaecheIsSelected() {
        var self = this;
        var ueberdaemmteflaecheAktiv = self.get('ueberdaemmteflaecheAktiv');
        self.set('ueberdaemmteflaecheAktiv', !ueberdaemmteflaecheAktiv);

        if (ueberdaemmteflaecheAktiv === false) {
          document.getElementsByName('ueberdaemmteflaeche')[0].setSelectionRange(0, this.get('ueberdaemmteflaeche').length);
        }
      }
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      switch (event.target.name) {
        case 'fhoehe':
          if (this.openFromFile || this.errors.fhoehe.length === 0) {
            value = parseFloat(value).toFixed(2);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('firsthoehe', value);

            var dachgeometriedata = self.controllerFor('application').get('model').dachgeometrie.objectAt(0);
            dachgeometriedata.set('hFirst', (parseFloat(self.get('fhoehe')) * 100).toFixed(1));

            x3d.set('firsthoeheEingetragen', true);
          } else {
            x3d.set('firsthoeheEingetragen', false);
          }
          break;

        case 'dneigung':
          if (this.openFromFile || this.errors.dneigung.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('dachneigung', value);

            if (self.get('dachneigungWalmGleichDachneigung1')) {
              bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('dachneigung2', value);
              self.set('dneigung2', value);
              x3d.set('dneigung2Eingetragen', true);
            } else {
              if (this.get('walmdach')) {
                this.setDachneigung2();
              }
            }
            x3d.set('dneigungEingetragen', true);
          } else {
            x3d.set('dneigungEingetragen', false);
          }
          break;
        case 'dneigung2':
          if (this.openFromFile || this.errors.dneigung2.length === 0) {
            value = parseFloat(value).toFixed(1);

            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('dachneigung2', value);
            x3d.set('dneigung2Eingetragen', true);
          } else {
            x3d.set('dneigung2Eingetragen', false);
          }
          break;
        case 'gbreite':
          if (this.openFromFile || this.errors.gbreite.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('gebaeudebreite', value);
            x3d.set('gbreiteEingetragen', true);
          } else {
            x3d.set('gbreiteEingetragen', false);
          }
          break;
        case 'glaenge':
          if (this.openFromFile || this.errors.glaenge.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('gebaeudelaenge', value);
            x3d.set('glaengeEingetragen', true);
          } else {
            x3d.set('glaengeEingetragen', false);
          }
          break;
        case 'uegiebel':
          if (this.openFromFile || this.errors.uegiebel.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('giebelueberstand', value);
            x3d.set('uegiebelEingetragen', true);
          } else {
            x3d.set('uegiebelEingetragen', false);
          }
          break;
        case 'uetraufe':
          if (this.openFromFile || this.errors.uetraufe.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('traufeueberstand', value);
            x3d.set('uetraufeEingetragen', true);
          } else {
            x3d.set('uetraufeEingetragen', false);
          }
          break;
        case 'uefirst':
          if (this.openFromFile || this.errors.uefirst.length === 0) {
            value = parseFloat(value).toFixed(1);
            bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('firstueberstand', value);
            x3d.set('uefirstEingetragen', true);
          } else {
            x3d.set('uefirstEingetragen', false);
          }
          break;
      }
      self.setGrenzwert(this.get('dachgeometrie'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    setDachneigung2: function setDachneigung2() {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var self = this;

      var dn1 = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('dachneigung');
      var dn2 = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('dachneigung2');

      var fristHoehe = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('firsthoehe');
      var gebLaenge = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('gebaeudelaenge');
      var gebBreite = x3d.get('bauteile').findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).get('gebaeudebreite');
      var maxGiebelHoehe = gebLaenge / 2 * Math.tan(dn1 * Math.PI / 180);

      if (maxGiebelHoehe > fristHoehe) {
        maxGiebelHoehe = fristHoehe;
      }

      var maxDneigung2 = 80;

      if (Math.atan(maxGiebelHoehe / (gebBreite / 2)) < 80 * Math.PI / 180) {

        maxDneigung2 = Math.atan(maxGiebelHoehe / (gebBreite / 2)) * 180 / Math.PI;

        this.set('validations.dneigung2.numericality.lessThanOrEqualTo', maxDneigung2);
        self.set('ttDachneigungWalm', self.get('i18n').t('wertebereich') + " 5 - " + maxDneigung2.toFixed(1) + " [°]");
        bauteile.findBy('id', 'dachgeometrie').get('gebaeudemasse').objectAt(0).set('dachneigung1', maxDneigung2.toFixed(1));
      } else {
        this.set('validations.dneigung2.numericality.lessThanOrEqualTo', maxDneigung2);
        self.set('ttDachneigungWalm', self.get('i18n').t('wertebereich') + " 5 - " + maxDneigung2.toFixed(1) + " [°]");
      }
    },

    setKonstruktion: function setKonstruktion() {
      var self = this;
      var value = false;

      var application = this.controllerFor('application');
      var daemmung = this.controllerFor('daemmung');
      var unterkonstruktion = this.controllerFor('unterkonstruktion');
      var lasteinwirkungsReiter = this.controllerFor(application.get('lasteinwirkungsReiter'));
      var letzterLasteinwirkungsReiter = this.controllerFor(self.get('letzterLasteinwirkungsReiter'));

      var x3d = application.get('model').x3ddefault.objectAt(0);
      var dachgeometriedata = application.get('model').dachgeometrie.objectAt(0);
      dachgeometriedata.set('konstruktionID', self.get('konstruktion'));

      console.log("##### setKonstruktion #####");
      console.log(application.get('lasteinwirkungsReiter'));

      if (self.get('konstruktion') === 1) {
        value = true;

        self.set('uegiebel', Number("0").toFixed(1));
        self.set('uetraufe', Number("0").toFixed(1));
        self.set('uefirst', Number("0").toFixed(1));

        daemmung.set('befestigungsvariante', 0);
        unterkonstruktion.set('istFassade', true);
        lasteinwirkungsReiter.set('istFassade', true);
        lasteinwirkungsReiter.set('dacheindeckung', 0);

        self.set('letzterLasteinwirkungsReiter', application.get('lasteinwirkungsReiter'));

        x3d.set('istFassade', true);
      } else {

        unterkonstruktion.set('istFassade', false);
        letzterLasteinwirkungsReiter.set('istFassade', false);

        if (self.get('bauder') === true) {
          letzterLasteinwirkungsReiter.set('dacheindeckung', 4);
        } else {
          letzterLasteinwirkungsReiter.set('dacheindeckung', 2);
        }

        x3d.set('istFassade', false);
      }

      unterkonstruktion.setUnterkonstruktionen();
      daemmung.setBfVarianten();

      return value;
    },

    ueberstaendeGesperrt: (function () {
      var self = this;
      return self.setKonstruktion();
    }).property('konstruktion'),

    setGrenzwert: function setGrenzwert(dachgeometrie) {
      var self = this;

      var gebBreite = Number(self.get('gbreite'));
      var gebLaenge = Number(self.get('glaenge'));
      var gebHoehe = Number(self.get('fhoehe'));
      var uetraufe = Number(self.get('uetraufe'));
      var uefirst = Number(self.get('uefirst'));
      var uegiebel = Number(self.get('uegiebel'));
      var tanDN = Math.tan(Number(self.get('dneigung')) * Math.PI / 180);
      var tanDNwalm = Math.tan(Number(self.get('dneigung2')) * Math.PI / 180);

      var minHoehe = 0.1,
          maxBreite = 999.9,
          minLaenge = 1;

      if (dachgeometrie === 0) {
        // Satteldach
        if (self.ungleichNANungleich0(gebHoehe) === true && self.ungleichNANungleich0(tanDN) === true) {
          maxBreite = 2 * gebHoehe / tanDN;
        }
        if (self.ungleichNANungleich0(gebBreite) === true && isNaN(uegiebel) === false && self.ungleichNANungleich0(tanDN) === true) {
          minHoehe = (gebBreite / 2 + uegiebel) * tanDN;
          if (minHoehe >= 25) {
            minHoehe = 25;
            maxBreite = 2 * gebHoehe / tanDN;
          }
        }
      } else if (dachgeometrie === 1) {
        // Pultdach
        if (self.ungleichNANungleich0(gebHoehe) === true && self.ungleichNANungleich0(tanDN) === true) {
          maxBreite = gebHoehe / tanDN;
        }
        if (self.ungleichNANungleich0(gebBreite) === true && isNaN(uefirst) === false && isNaN(uetraufe) === false && self.ungleichNANungleich0(tanDN) === true) {
          minHoehe = (gebBreite + uefirst + uetraufe) * tanDN;
          if (minHoehe >= 25) {
            minHoehe = 25;
            maxBreite = gebHoehe / tanDN - uetraufe;
          }
        }
      } else if (dachgeometrie === 2) {
        // walmdach
        if (self.ungleichNANungleich0(gebHoehe) === true && self.ungleichNANungleich0(tanDN) === true) {
          maxBreite = 2 * gebHoehe / tanDN;
        }
        minLaenge = gebBreite;
        if (self.ungleichNANungleich0(gebLaenge) === true && isNaN(uegiebel) === false && self.ungleichNANungleich0(tanDNwalm) === true) {
          minHoehe = ([gebBreite, gebLaenge].sort(function (a, b) {
            return a - b;
          })[0] / 2 + uegiebel) * tanDNwalm;
          if (minHoehe >= 25) {
            minHoehe = 25;
            maxBreite = 2 * gebHoehe / tanDN;
          }
        }
      }

      this.set('validations.gbreite.numericality.lessThanOrEqualTo', maxBreite);
      this.set('validations.glaenge.numericality.greaterThanOrEqualTo', minLaenge);
      this.set('validations.fhoehe.numericality.greaterThanOrEqualTo', minHoehe);

      self.set('ttGebaeudebreite', self.get('i18n').t('wertebereich') + " 1 - " + maxBreite.toFixed(1) + " [m]");
      self.set('ttGebaeudelaenge', self.get('i18n').t('wertebereich') + ' ' + minLaenge.toFixed(1) + " - 999.9 [m]");
      self.set('ttHoeheFirst', self.get('i18n').t('wertebereich') + ' ' + minHoehe.toFixed(1) + " - 25.0 [m]");

      self.set('openFromFile', true);
      self.send('validation', -1, { target: { name: "foo" } });
      self.set('openFromFile', false);
    },

    ungleichNANungleich0: function ungleichNANungleich0(value) {
      var self = this;
      if (isNaN(value) === false && value !== 0) {
        return true;
      } else {
        return false;
      }
    }
  });

});